body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: black;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
#mvhackstext {
	font-size: clamp(16px, 5vw, 2.25rem);
}
#footer {
	text-align: center;
}

#alert {
	height: 39px;
	background-color: #81807F;
	font-size: 14.9;
	font-family: "Montserrat", sans-serif;
	padding-left: 30px;
}

#devpost {
	width: 250px;
	background-color: blueviolet;
}

#contactUs {
	width: 250px;
	background-color: blueviolet;
}

#arrow {
	margin-right: -10px;
	margin-left: 27px;
}

#blurbSponsors {
	color: white;
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 25px;
	padding-top: 5%;
}

#email {
	color: rgb(147, 147, 230)
}

#downloadProspectus {
	color: rgb(147, 147, 230)
}

#prospectusImage {
	padding-left: 10%;
	padding-right: 10%;
	padding-bottom: 20px;

}


